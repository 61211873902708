import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BusinessGuard  {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isSubdomain = (trial: any) => trial.split('.').length > 2;
    const { hostname } = new URL(window.location.href);
    if ((hostname !== 'prod.terragoncpaas.com' && hostname !== 'dev.terragoncpaas.com') && isSubdomain(hostname)) {
      localStorage.setItem('tenant', 'true');
      localStorage.setItem('subDomainName', window.location.href);
    } else {
      console.log('not tenant');
      localStorage.setItem('tenant', 'false');
    }
    const isTenant = localStorage.getItem('tenant');
    if (isTenant === 'false') {
      return true;
    } else {
      console.log('Not authorized');
      this.router.navigate(['/dashboard']);
      return false;
    }
  }
}
