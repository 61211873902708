import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../app/_helpers/auth.guard';
import { BusinessGuard } from './business/business.guard';

const AuthModule = () => import('./auth/auth.module').then((x) => x.AuthModule);
const ClientModule = () =>
  import('./client/client.module').then((x) => x.ClientModule);
const AccountModule = () =>
  import('./account/account.module').then((x) => x.AccountModule);
const UserModule = () => import('./user/user.module').then((x) => x.UserModule);
const WalletModule = () =>
  import('./wallet/wallet.module').then((x) => x.WalletModule);
const NotificationModule = () =>
  import('./notification/notification.module').then(
    (x) => x.NotificationModule
  );
const TerrasenseModule = () =>
  import('./terrasense/terrasense.module').then((x) => x.TerrasenseModule);
const TerraconnectModule = () =>
  import('./terraconnect/terraconnect.module').then(
    (x) => x.TerraconnectModule
  );
const WhatsappModule = () =>
  import('./whatsapp/whatsapp.module').then((x) => x.WhatsappModule);
const TerracaptureModule = () =>
  import('./terracapture/terracapture.module').then(
    (x) => x.TerracaptureModule
  );
const SharedModule = () =>
  import('./shared/shared.module').then((x) => x.SharedModule);
const DashboardModule = () =>
  import('./dashboard/dashboard.module').then((x) => x.DashboardModule);
const MaintenanceModule = () =>
  import('./maintenance/maintenance.module').then((x) => x.MaintenanceModule);
const InboxModule = () =>
  import('./inbox/inbox.module').then((x) => x.InboxModule);
const ErrorModule = () =>
  import('./error-module/error-module.module').then((x) => x.ErrorModule);
const RatesModule = () =>
  import('./rates/rates.module').then((x) => x.RatesModule);
const MessageReportModule = () =>
  import('./message-report/message-report.module').then(
    (x) => x.MessageReportModule
  );
const AuditLogsModule = () =>
  import('./audit-logs/audit-logs.module').then((x) => x.AuditLogsModule);
const AudienceModule = () =>
  import('./audience/audience.module').then((x) => x.AudienceModule);
const CpaasPlusModule = () =>
  import('./cpaas-plus/cpaas-plus.module').then((x) => x.CpaasPlusModule);
const BusinessModule = () =>
  import('./business/business.module').then((m) => m.BusinessModule);

const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: '/maintenance/index' },
  { path: '', pathMatch: 'full', redirectTo: '/auth/login' },
  { path: 'auth', loadChildren: AuthModule },
  { path: 'maintenance', loadChildren: MaintenanceModule },
  {
    path: 'client',
    loadChildren: ClientModule,
    canActivate: [AuthGuard],
    data: { roles: [1, 2] },
  },
  { path: 'account', loadChildren: AccountModule, canActivate: [AuthGuard] },
  {
    path: 'user',
    loadChildren: UserModule,
    canActivate: [AuthGuard],
    data: { roles: [1, 3] },
  },
  {
    path: 'wallet',
    loadChildren: WalletModule,
    canActivate: [AuthGuard],
    data: { roles: [1, 2, 3] },
  },
  {
    path: 'notification',
    loadChildren: NotificationModule,
    canActivate: [AuthGuard],
    data: { roles: [1, 2, 3] },
  },
  {
    path: 'audience',
    loadChildren: TerrasenseModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'campaign',
    loadChildren: TerraconnectModule,
    canActivate: [AuthGuard],
    data: { id: '1', name: 'Angular' },
  },
  {
    path: 'datasource',
    loadChildren: TerracaptureModule,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: DashboardModule,
    canActivate: [AuthGuard],
    data: { roles: [1, 2, 3, 4], id: '2', name: 'dashboard' },
  },
  {
    path: 'integrations',
    loadChildren: WhatsappModule,
    canActivate: [AuthGuard],
  },
  { path: 'shared', loadChildren: SharedModule, canActivate: [AuthGuard] },
  { path: 'inbox', loadChildren: InboxModule, canActivate: [AuthGuard] },
  { path: 'rates', loadChildren: RatesModule },
  { path: 'message-report', loadChildren: MessageReportModule },
  { path: 'audit-logs', loadChildren: AuditLogsModule },
  { path: 'audiences', loadChildren: AudienceModule, canActivate: [AuthGuard] },
  { path: 'plus', loadChildren: CpaasPlusModule, canActivate: [AuthGuard] },
  { path: 'error', loadChildren: ErrorModule },
  {
    path: 'business',
    loadChildren: BusinessModule,
    canActivate: [AuthGuard, BusinessGuard],
  },
  { path: '**', redirectTo: '/auth/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
