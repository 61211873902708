import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/_services/auth.service';
// import PubNub from 'pubnub';
import { formatDate } from '@angular/common';
import { environment } from '../../environments/environment';
import * as Sentry from "@sentry/angular-ivy";
interface notificationMessage {
  type: string;
  id: number;
  senderName: string;
  senderMobile: number;
  timeStamp: string;
  content: string;
  viewStatus: boolean;
  channel: string;
}

interface dataUploadNotification {
  type: string;
  id: number;
  sengmentId: string;
  source: string;
  userId: number;
  businessId: number;
  totalProfiles: number;
  segmentName: string;
  uploadMode: string;
  uploadedProfiles: number;
  status: string;
}

interface notificationMessageBox {
  type: string;
  message: number;
  id: string;
  timetoken: string;
}

interface wabaNotification {
  type: string;
  message: number;
  timetoken: string;
}

@Injectable({
  providedIn: 'root'
})
export class InboxNotificationService {

  subject = new Subject();
  // pubnub: any;
  unreadChannels: any;

  constructor(private authService: AuthService,
    @Inject(LOCALE_ID) public locale: string) {
    // const pubnub = new PubNub({
    //   publishKey: this.authService.getPubnubPublichKey(),
    //   subscribeKey: this.authService.getPubnubSubscribeKey(),
    //   uuid: this.authService.getUser()['business_id'] != null ? this.authService.getUser()['business_id'].toString() : this.authService.getUser()['id'].toString()
    // } as any);
    // this.pubnub = pubnub;
    this.getUnreadMessages();
  }

  async getUnreadMessages() {
    let userData;
    // try {
    //   userData = await this.pubnub.objects.getUUIDMetadata({
    //     uuid: this.authService.getUser()['business_id'] != null ? this.authService.getUser()['business_id'].toString() : this.authService.getUser()['id'].toString(),
    //   });
    // } catch (status) {
    // }
    // if (userData && userData != undefined) {
    //   if (userData?.data?.custom?.unreadChannels !== "") {
    //     //let date = new Date(userData.data.updated);
    //     this.unreadChannels = JSON.parse(userData?.data?.custom?.unreadChannels);
    //     this.pubnub.fetchMessages(
    //       {
    //         channels: JSON.parse(userData?.data?.custom?.unreadChannels), // get this from userData.unreadChannels
    //         end: userData.data.custom.lastReadTime ? userData.data.custom.lastReadTime : (Number((new Date().getTime() / 1000).toFixed()) * 10000000), // 17-digit precision unix time
    //         count: 25, // default/max is 25 messages for multiple channels (up to 500)
    //         includeMeta: true
    //       },
    //       function (status: any, response: any) {
    //         if (status.error === false) {
    //           addUnreadMessagesToList(response);
    //         }
    //       }
    //     );
    //   }
    // }
    // const addUnreadMessagesToList = (response: any) => {
    //   for (let key in response.channels) {
    //     response.channels[key].forEach((event: any) => {
    //       if (!event.message.isOwn && event.meta?.isCustomer) {
    //         let message: notificationMessage = {
    //           type: event.messageType,
    //           id: 1,
    //           senderName: event.meta ? event.meta.channelMetaData.name : event.channel.split("_")[1],
    //           senderMobile: 1,
    //           timeStamp: event.meta?.timetoken ? formatDate(new Date(event.meta.timetoken / 10000), 'MMM d, y, h:mm:ss a', this.locale) : formatDate(new Date(event.timetoken / 10000), 'MMM d, y, h:mm:ss a', this.locale),
    //           content: event.message?.text,
    //           viewStatus: false,
    //           channel: event.channel
    //         };
    //         this.subject.next(message);
    //       }
    //     })
    //   }
    // }
  }

  addWhatsappMesageToList(event: any) {
    if (event.userMetadata.type === 'data_upload') {
      let message: dataUploadNotification = {
        type: event.userMetadata.type,
        id: 1,
        sengmentId: event.userMetadata.segment_id,
        source: event.userMetadata.others.segment.datasource,
        userId: event.userMetadata.others.segment.tenant_id,
        businessId: event.userMetadata.others.segment.business_id,
        totalProfiles: event.userMetadata.others.total_profiles,
        segmentName: event.userMetadata.others.segment.name,
        uploadMode: event.userMetadata.others.segment.upload_mode.name,
        uploadedProfiles: event.userMetadata.others.uploaded_profiles,
        status: event.userMetadata.status
      };
      this.subject.next(message);
    } else if (event.userMetadata.type === 'campaign_status') {
      let message: notificationMessageBox = {
        type: event.userMetadata.type,
        message: event.message.text,
        id: event.userMetadata.id,
        timetoken: formatDate(new Date(event.timetoken / 10000), 'MMM d, y, h:mm:ss a', this.locale),
      };
      this.subject.next(message);
      this.authService.getNotificationCount();
    } else if (event.userMetadata.type === 'waba_creation') {
      let message: wabaNotification = {
        type: event.userMetadata.type,
        message: event.message.text,
        timetoken: formatDate(new Date(event.timetoken / 10000), 'MMM d, y, h:mm:ss a', this.locale),
      };
      this.subject.next(message);
    } else {
      if (event.userMetadata?.isCustomer) {
        let message: notificationMessage = {
          type: event.type,
          id: 1,
          senderName: (event.userMetadata?.channelMetaData?.name && event.userMetadata?.channelMetaData?.name.length > 0) ? event.userMetadata?.channelMetaData?.name : event.channel.split('_')[1],
          senderMobile: 1,
          timeStamp: event.message.timestamp ? formatDate(new Date(event.message.timestamp / 10000), 'MMM d, y, h:mm:ss a', this.locale) : formatDate(new Date(event.timetoken / 10000), 'MMM d, y, h:mm:ss a', this.locale),
          content: event.message?.text,
          viewStatus: false,
          channel: event.channel
        };
        this.subject.next(message);
      }
    }
  }

  inboxNotificationChange(event: any) {
    if (event == false) {
      //this.whatsappNotificationList = [];
    }
  }

  handleMessage = (event: any) => {
    this.addWhatsappMesageToList(event);
  };

  // pubnubListener = {
  //   message: this.handleMessage
  // }

  stopListener() {
    let channelList = this.authService.getWhatsappChannelList()?.split('/');
    let user = this.authService.getUser();
    let channel = user.role_id == 3 ? 'tmp_' + user.business_id + '_' + user.id : 'tmp_' + user.id;
    // this.pubnub.unsubscribe({
    //   channelGroups: channelList,
    //   channels: [channel, channel + '_' + environment.envVaribale]
    // });
    // this.pubnub.removeListener(this.pubnubListener);
    // this.pubnub.stop();
  }

  startListener() {
    // this.pubnub.addListener(this.pubnubListener);

    // let channelList = this.authService.getWhatsappChannelList()?.split('/');
    // let user = this.authService.getUser();
    // let channel = user.role_id == 3 ? 'tmp_' + user.business_id + '_' + user.id : 'tmp_' + user.id;
    // this.pubnub.subscribe({
    //   channelGroups: channelList,
    //   channels: [channel, channel + '_' + environment.envVaribale]
    // },
    // (error:any) => {
    //   Sentry.captureMessage('Error: ' + error);
    // });
  }

  async markMessagesAsread(channel: any) {
    let enddate = Number((new Date().getTime() / 1000).toFixed()) * 10000000;
    let data: any;
    if (channel) {
      let userData: any;
      // try {
      //   userData = await this.pubnub.objects.getUUIDMetadata({
      //     uuid: this.authService.getUser()['business_id'] != null ? this.authService.getUser()['business_id'].toString() : this.authService.getUser()['id'].toString()
      //   });
      // } catch (status) {
      // }
      // let unreadChannels = JSON.parse(userData.data.custom.unreadChannels);
      // let index = unreadChannels.findIndex((element: any) => element === channel);
      // unreadChannels.splice(index, 1);
      // data = {
      //   custom: {
      //     unreadChannels: JSON.stringify(unreadChannels),
      //     lastReadTime: enddate
      //   }
      // }
    } else {
      data = {
        custom: {
          unreadChannels: "",
          lastReadTime: enddate
        }
      }
    }
    // this.pubnub.objects.setUUIDMetadata({
    //   uuid: this.authService.getUser()['business_id'] != null ? this.authService.getUser()['business_id'].toString() : this.authService.getUser()['id'].toString(),
    //   data: data
    // }, function (status: any, response: any) {
    //   if (!status.error) { }
    // });
  }

}
