<!-- <p>paginator works!</p> -->

<!-- <section class="container-fluid">
  <div class="row">
    <div (click)="navigatePage(currentPage-1)" class="col-4">
      previous
    </div>
    <div class="col-4"></div>
    <div (click)="navigatePage(currentPage+1)" class="col-4">
      next
    </div>
  </div>

</section> -->

<div class="trgm-prm-lt-paginator-outr-div pt-6">
  <span class="trgm-prm-lt-paginator-outr-desc"
    >Showing {{ start }} to
    <span class="border border-[#E4E7EC] rounded-md py-2 px-3 mx-1">{{
      end
    }}</span>
    of {{ countLoading ? "..." : totalCount + " entries" }}
  </span>
  <div class="trgm-prm-lt-paginator-page-outer">
    <div
      class="trgm-prm-lt-paginator-page-btn"
      [ngClass]="{ inactive: pageNumber === 1 }"
      (click)="previous()"
    >
      <span><</span><span>Previous</span>
    </div>
    <div style="display: flex">
      <div
        *ngIf="
          totalPageCount > pageLimit &&
          (pageNumber === totalPageCount || pageNumber === totalPageCount - 1)
        "
        class="trgm-prm-lt-paginator-page-btn"
        style="cursor: default"
      >
        ...
      </div>
      <div
        *ngFor="let item of pageCount; let i = index"
        style="cursor: default"
        class="trgm-prm-lt-paginator-page-btn"
        [ngClass]="{ active: item === pageNumber }"
        (click)="jump(item)"
      >
        {{ item }}
      </div>
      <div
        *ngIf="totalPageCount > pageLimit && pageNumber < totalPageCount - 1"
        class="trgm-prm-lt-paginator-page-btn"
        style="cursor: default"
      >
        ...
      </div>
      <div
        *ngIf="totalPageCount > pageLimit && !isPageShown(totalPageCount)"
        class="trgm-prm-lt-paginator-page-btn"
        [ngClass]="{ active: pageNumber === pageCount }"
        (click)="jump(totalPageCount)"
      >
        {{ totalPageCount }}
      </div>
    </div>
    <div
      class="trgm-prm-lt-paginator-page-btn"
      style="border: none"
      [ngClass]="{ inactive: pageNumber === totalPageCount }"
      (click)="next()"
    >
      <span>Next</span><span>></span>
    </div>
  </div>
</div>
